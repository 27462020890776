import React, { useState } from "react"
import { Link } from 'gatsby'

import Layout from "~components/layout/layout.component"
import TeamSignUp from '~components/team-sign-up/team-sign-up.component';
import Modal from '~components/modal/modal.component';
import SearchOrganizations from '~components/search-organizations/search-organizations.component';
import SEO from "~components/seo/seo.component"

import useStyles from '~components/team/team.styles';

const TeamPage = props => {
  const classes = useStyles(props);

  const [organizationId, setOrganizationId] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  return (
    <Layout>
      <SEO title="Find Your Organization" />
      <h1>Find Your Organization</h1>
      <SearchOrganizations
        className={classes.searchOrganizations}
        onSelectedOrganizationChange={
          (organizationId, organizationName) => {
            setOrganizationId(organizationId)
            setOrganizationName(organizationName)
            }
        } 
      />
      <Modal
        title={organizationName}
        isShowing={organizationId}
        hide={() => setOrganizationId(null)}
      >
        <TeamSignUp organizationHash={organizationId} />
      </Modal>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
    )
  }
  
  export default TeamPage
  