import React from 'react';
import Button from '~components/button/button.component';

import {
  ANNUAL_COST_USD,
  CURRENCY,
  LOCALE
} from '~common/constants';

import useStyles from './team-sign-up.styles';

const TeamSignUp = props => {

  const classes = useStyles(props);

  return (
    props.organizationHash ? 
    <div className={classes.teamSignUp}>
      <p>To sign up for text alerts or for email alerts click continue.</p>
      <p>If your organization has chosen Recipient's Choice, the cost is {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(ANNUAL_COST_USD)} per year if for unlimited text alerts.</p>
      <p>The cost for email alerts is free.</p>
      <p>Information that you supply here will be stored by us and used to provide the services you are signing up for. You can opt-out of RainedOut's services by clicking the Unsubscribe button at the bottom of our website.</p>
      <Button
        className={classes.button}
        href={`https://www.rainedout.net/team_page.php?a=${props.organizationHash}`}
        iconRight="arrow-right"
        shadow={true}
        text="Continue"
        theme="blue"
      />
    </div> : null
  )
}

export default TeamSignUp
