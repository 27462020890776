import {createUseStyles} from 'react-jss'

import {
  link
} from '~styles/mixins.styles'

import {
  COLORS
} from '~styles/vars.styles'

export default createUseStyles({
  teamSignUp: {},

  smsHighlight: {
    color: COLORS.PURPLE_01,
    fontWeight: 600
  },

  link: {
    ...link({
      color: COLORS.BLUE_02,
      fontSize: 16
    })
  }
})